<template>
	<div>
		<el-form :inline="true" :model="params" :size="theme.size" v-if="!theme.is_mobile">
			<el-form-item label="关键词">
				<el-input v-model="params.keywords" placeholder="请输入应用名称关键词" clearable></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="getWechat({ ...params, page: 1})">查询</el-button>
				<el-button type="primary" @click="drawer = true">添加应用</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="apps" :max-height="theme.height - 310" :size="theme.size">
			<el-table-column prop="id" label="ID"></el-table-column>
			<el-table-column prop="name" label="应用名称"></el-table-column>
			<el-table-column prop="app_id" label="appId"></el-table-column>
			<el-table-column prop="updated_at" label="更新时间" width="180"></el-table-column>
			<el-table-column prop="created_at" label="创建时间" width="180"></el-table-column>
			<el-table-column fixed="right" label="更多操作" width="120">
				<template slot-scope="scope">
					<el-button type="text" @click="createForm = scope.row; drawer = true">编辑</el-button>
					<el-button type="text" @click="onDelete(scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getWechat({ ...params, page: 1, perPage: v}) }"
			@current-change="(v) => { getWechat({ ...params, page: v}) }"
			background>	
		</el-pagination>
		<el-dialog :title="createForm.id?'编辑应用':'添加应用'" :visible.sync="drawer" @closed="createForm = {}; drawer = false">
			<div style="padding: 0 15px;">
				<el-form status-icon :model="createForm" :size="theme.size" ref="createForm" label-width="80px">
					<el-form-item label="应用名称" prop="name" required>
						<el-input v-model="createForm.name" autocomplete="off" placeholder="请输入应用名称"></el-input>
					</el-form-item>
					<el-form-item label="应用ID" prop="app_id" required>
						<el-input v-model="createForm.app_id" autocomplete="off" placeholder="请输入应用ID" :disabled="createForm.id"></el-input>
					</el-form-item>
					<el-form-item label="应用私钥" prop="rsa_private_key" required>
						<el-input v-model="createForm.rsa_private_key" autocomplete="off" placeholder="请输入应用私钥" type="textarea" rows="12" :disabled="createForm.id"></el-input>
					</el-form-item>
					<el-form-item label="阿里公钥" prop="alipay_rsa_public_key" required>
						<el-input v-model="createForm.alipay_rsa_public_key" autocomplete="off" placeholder="请输入阿里公钥" type="textarea" rows="6" :disabled="createForm.id"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button @click="resetForm('createForm')">取 消</el-button>
						<el-button type="primary" @click="submitForm('createForm')">确 定</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	export default {
		computed: {
			...mapState(['theme'])
		},
		methods: {
			onDelete (r) {
				this.$confirm('确定要删除【'+r.name+'】应用吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_ALIPAY_APPS, {params: {action: 'destroy', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.$message({
						type: 'success',
						duration: 1000,
						message: msg,
						onClose: () => {
							this.getWechat(this.params)
						}
					});
				})
			},
			resetForm (ref) {
				this.drawer = false;
				this.$refs[ref].resetFields();
				this.createForm = {
					type: 0
				};
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error('提交有误！');
					this.submitWechat(this.createForm);
				})
			},
			async submitWechat (data) {
				const res = await this.$http.post(this.$api.URI_ALIPAY_APPS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 2000,
					message: msg,
					onClose: () => {
						this.drawer = false;
						this.getWechat(this.params);
					}
				});
			},
			async getWechat (p, l = false) {
				const res = await this.$http.get(this.$api.URI_ALIPAY_APPS, {params: p, headers: {loading: l}});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.apps = result.data;
				this.params = {
					...p,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				apps: [],
				drawer: false,
				createForm: {},
				params: {
					perPage: 10,
				},
			}
		},
		created () {
			this.getWechat(this.params, true);
		}
	};
</script>
