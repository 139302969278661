<template>
	<el-tabs :tab-position="theme.width > 980 ? 'left' : 'top'" :style="{height: (theme.height - 160)+'px'}" v-model="activeTab">
		<el-tab-pane label="微信应用" name="wechat">
			<wechat-apps></wechat-apps>
		</el-tab-pane>
		<el-tab-pane label="微信商户" name="merchant">
			<wechat-merchants></wechat-merchants>
		</el-tab-pane>
		<el-tab-pane label="头条应用" name="toutiao">
			<toutiao-apps></toutiao-apps>
		</el-tab-pane>
		<el-tab-pane label="百度应用" name="baidu">
			<baidu-apps></baidu-apps>
		</el-tab-pane>
		<el-tab-pane label="支付宝应用" name="alipay">
			<alipay-apps></alipay-apps>
		</el-tab-pane>
		<el-tab-pane label="企业微信应用" name="wochat">
			<wochat-apps></wochat-apps>
		</el-tab-pane>
	</el-tabs>
</template>

<script>
	import { mapState } from 'vuex';
	import wechatApps from './wechat/index';
	import wochatApps from './wochat';
	import toutiaoApps from './toutiao';
	import baiduApps from './baidu';
	import alipayApps from './alipay';
	import wechatMerchants from './wechat/merchants';

	export default {
		computed: {
			...mapState(['theme'])
		},
		components: {
			wechatApps,
			wochatApps,
			toutiaoApps,
			baiduApps,
			alipayApps,
			wechatMerchants
		},
		methods: {
		},
		data() {
			return {
				activeTab: 'wechat'
			}
		},
		async created () {

		}
	};
</script>